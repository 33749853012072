import React, { useState, useEffect} from 'react';
import './index.css'
import About from './components/about/About'
import Contact from './components/contact/Contact'
import Expirence from './components/expirence/Expirence'
import Footer from './components/footer/Footer'
import Header from './components/header/Header'
import Home from './components/home/Home'
import Portfolio from './components/portfolio/Portfolio'
import Skills from './components/skills/Skills'
import CountUp from 'react-countup';
import { motion } from "framer-motion"
// import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import { ScrollerMotion } from 'scroller-motion'




const App = () => {




  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4800);
  }, []);

  // const [isActive, setIsActive] = useState(true);

  // const onEnd = () => {
  //   setIsActive(current => !current);
  // };
  // whileInView={isActive ? '{{ opacity: [1, 0] }}' : ''}

  return (
    <div>
      {loading ? (
        <motion.div className="loader-screen" animate={{ y: -1000, }} transition={{ delay: 4, duration: 1 }}>
          <div className="loader-count">
            <CountUp
              start={0}
              end={100}
              delay={0}
              duration={3.8}
              // onEnd={onEnd}
              suffix="%"
              easing="easeOutSine"
            >
              {({ countUpRef }) => (
                <div>
                  <span ref={countUpRef}></span>
                </div>
              )}
            </CountUp>
          </div>
        </motion.div>
      ) : (
        <div>
          <ScrollerMotion>
              <Header />
              <Home />
              <About />
              <Skills />
              <Expirence />
              <Portfolio />
              <Contact />
              <Footer />
            </ScrollerMotion>
            </div>
      )}
    </div>
  )
}

export default App