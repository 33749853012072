import React from 'react'
import './header.css'
import { motion } from "framer-motion"
import { Link} from 'react-scroll'
import logo from './mlogo.png';
import pdf from './madusanka-jayasooriya-resume.pdf';

const Header = () => {
  return (
    <div>
        <motion.div initial={{ y: -50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.5, type: "spring", stiffness: 100 }} className="navbar z-[999] py-4 px-0 bg-white fixed left-0 w-full m-auto top-0">
        <div className="container mx-auto pr-3 md:px-0">
          <div className="navbar-start flex items-center">
            <div className="dropdown">
              <label tabIndex={0} className="btn btn-ghost lg:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
              </label>
              <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-white rounded-box w-52">
              <li><Link to="home" spy={false} smooth={true} offset={-50} duration={1000}>Home</Link></li>
              <li><Link to="about" spy={false} smooth={true} offset={-50} duration={1000}>About</Link></li>
              <li><Link to="skills" spy={false} smooth={true} offset={-50} duration={1000}>Skills</Link></li>
              <li><Link to="expirence" spy={false} smooth={true} offset={-50} duration={1000}>Expirence</Link></li>
              <li><Link to="portfolio" spy={false} smooth={true} offset={-50} duration={1000}>Portfolio</Link></li>
              <li><Link to="contact" spy={false} smooth={true} offset={-50} duration={1000}>Contact</Link></li>
              </ul>
            </div>
            <a><img src={logo} alt="logo" /></a>
          </div>
          <div className="navbar-center hidden lg:flex">
            <ul className="menu menu-horizontal px-1">
              <li><Link to="home" spy={false} smooth={true} offset={-50} duration={1000}>Home</Link></li>
              <li><Link to="about" spy={false} smooth={true} offset={-50} duration={1000}>About</Link></li>
              <li><Link to="skills" spy={false} smooth={true} offset={-50} duration={1000}>Skills</Link></li>
              <li><Link to="expirence" spy={false} smooth={true} offset={-50} duration={1000}>Expirence</Link></li>
              <li><Link to="portfolio" spy={false} smooth={true} offset={-50} duration={1000}>Portfolio</Link></li>
              <li><Link to="contact" spy={false} smooth={true} offset={-50} duration={1000}>Contact</Link></li>
            </ul>
          </div>
          <div className="navbar-end flex">
            <motion.a whileHover={{scale: 1.1}} whileTap={{ scale: 0.9 }} className="btn btn-sm md:btn text-white no-animation" href={pdf} target='_blank'>Resume</motion.a>
          </div>
          </div>
        </motion.div>
    </div>

  )
}

export default Header