import React from 'react'
import './portfolio.css'
import { motion } from "framer-motion"

function Portfolio() {
  return (
    <div>
      <div className='py-[100px] bg-dark' id='portfolio'>
        <div className="container mx-auto">
          <div className="flex flex-wrap -mx-2">
            <div className="w-full px-4 md:px-2">
              <motion.h2 className='text-[60px] text-white text-center mb-8 leading-none' viewport={{ once: true }} whileInView={{ opacity: [0, 1] }} transition={{ duration: .500 }}>Portfolio</motion.h2>
            </div>
          </div>
          <div className="flex flex-wrap -mx-2">
            <div className="w-full md:w-12/12 lg:w-12/12 px-4 md:px-2 text-center">
              <motion.h1 className='text-[50px] md:text-[85px] text-white' viewport={{ once: true }} whileInView={{ opacity: [0, 1] }} transition={{ delay: .800, duration: .500 }}>Coming Soon</motion.h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Portfolio