import React from 'react'
import './about.css'
import { motion } from "framer-motion"


const About = () => {
  return (
    <div>
      <div className='py-[100px] bg-dark' id='about'>
        <div className="container mx-auto">
          <div className="flex flex-wrap -mx-2">
            <div className="w-full px-4 md:px-2">
              <motion.h2 className='text-[60px] text-white mb-8 leading-none' viewport={{ once: true }} whileInView={{ opacity: [0, 1] }} transition={{ delay:0.500, duration: .500 }}>About Me</motion.h2>
            </div>
          </div>
          <div className="flex flex-wrap -mx-2">
            <div className="w-full md:w-12/12 lg:w-9/12 px-4 md:px-2">
              <motion.p className='text-[25px] md:text-[35px] text-white' viewport={{ once: true }}  whileInView={{ opacity: [0, 1] }} transition={{ delay:.800, duration: .500 }}>I'm committed to keeping you ahead in today’s fast-paced and ever-evolving world by delivering digital solutions that solve problems in a secure, simple and effective way. I have had the opportunity to work on a variety of projects and have gained experience in both small and large scale web development. I am always seeking to expand my knowledge and take on new challenges in the field.</motion.p>
            </div>
            <div className="w-full md:w-12/12 lg:w-1/12 px-4 md:px-2"></div>
            <div className="w-full md:w-12/12 lg:w-2/12 px-4 md:px-2 flex items-center justify-center mt-[70px] lg:mt-[0px]">
              <div className="LI-profile-badge" data-version="v1" data-size="medium" data-locale="en_US" data-type="vertical" data-theme="dark" data-vanity="m-jayasooriya"><a className="LI-simple-link" href='https://lk.linkedin.com/in/m-jayasooriya?trk=profile-badge'>Madusanka Jayasooriya</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About