import React from 'react'
import './footer.css'

function Footer() {
  return (
    <div>
      <footer className='text-center bg-dark p-5'>
        <span className='text-gray-400 text-sm'>© {new Date().getFullYear()} All Rights Reserved</span>
      </footer>
    </div>
  )
}

export default Footer