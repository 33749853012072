import React from 'react'
import './expirence.css'
import exIcon from './briefcase-solid.svg';
import upIcon from './arrow-up-solid.svg';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function Expirence() {
  return (
    <div>
      <div className='py-[100px]' id='expirence'>
        <div className="container mx-auto">
          <div className="flex flex-wrap -mx-2">
            <div className="w-full px-4 md:px-2">
              <h2 className='text-[60px] text-center mb-10 leading-none'>Expirences</h2>
              <VerticalTimeline>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="2021 - June | 2023 - Present"
                  iconStyle={{ background: 'rgb(0, 0, 0)', color: '#fff' }}
                  icon={<img src={exIcon} alt="exIcon" />}
                >
                  <h3 className="vertical-timeline-element-title">Frontend Developer</h3>
                  <h4 className="vertical-timeline-element-subtitle">REDHILL PVT LTD</h4>
                  <p>
                    Working as a Frontend Developer from 2021 - June | 2023 - Present at Redhill. Singapore.
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date=" 2018 - December | 2020 - July"
                  iconStyle={{ background: 'rgb(0, 0, 0)', color: '#fff' }}
                  icon={<img src={exIcon} alt="exIcon" />}
                >
                  <h3 className="vertical-timeline-element-title">UI Developer</h3>
                  <h4 className="vertical-timeline-element-subtitle">CODEXBETA PVT LTD</h4>
                  <p>
                    Worked as a UI Developer from 2020 - January | 2021 - June at CODEXBETA PVT LTD. Nugegoda, Colombo.
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="2018 - May | 2018 - November"
                  iconStyle={{ background: 'rgb(0, 0, 0)', color: '#fff' }}
                  icon={<img src={exIcon} alt="exIcon" />}
                >
                  <h3 className="vertical-timeline-element-title">UI Developer (Intern)</h3>
                  <h4 className="vertical-timeline-element-subtitle">NEOLITH TECHNOLOGIES PVT LTD</h4>
                  <p>
                    Worked as a UI Developer - Intern from 2018 - may | 2018 - November Under NAITA ( Special Industrial Training) at Neolith Technologies PVT Ltd. Nugegoda, Colombo.
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  iconStyle={{ background: 'rgb(0, 0, 0)', color: '#fff' }}
                  icon={<img src={upIcon} alt="upIcon" />}
                />
              </VerticalTimeline>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Expirence