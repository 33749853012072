import React from "react";
import './home.css'
import { motion } from "framer-motion"


const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

const firstTitle = {
  initial: {
    y: -20,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.04,
      staggerDirection: -1
    }
  }
}

const lastTitle = {
  initial: {
    y: -20,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.04,
      staggerDirection: 1
    }
  }
}

const letter = {
  initial: {
    y: 400,
  },
  animate: {
    y: 0,
    transition: { duration: 1, ...transition }
  }
};

const Home = () => {
  return (
    <div>
      <div className="hero h-[100vh] md:h-[80vh] bg-white" id="home">
        <div className="container mx-auto">
          <div className="flex flex-wrap -mx-2">
            <div className="w-full md:w-12/12 lg:w-8/12 px-4 md:px-2">
              <motion.h1 className="font-bold leading-none first">
                <motion.span variants={firstTitle} initial="initial" animate="animate">
                  <motion.span variants={letter}>F</motion.span>
                  <motion.span variants={letter}>r</motion.span>
                  <motion.span variants={letter}>o</motion.span>
                  <motion.span variants={letter}>n</motion.span>
                  <motion.span variants={letter}>t</motion.span>
                  <motion.span variants={letter}>e</motion.span>
                  <motion.span variants={letter}>n</motion.span>
                  <motion.span variants={letter}>d</motion.span>
                </motion.span>
              </motion.h1>
              <motion.h1 className="font-bold leading-none last">
                <motion.span variants={lastTitle} initial="initial" animate="animate">
                  <motion.span variants={letter}>D</motion.span>
                  <motion.span variants={letter}>e</motion.span>
                  <motion.span variants={letter}>v</motion.span>
                  <motion.span variants={letter}>e</motion.span>
                  <motion.span variants={letter}>l</motion.span>
                  <motion.span variants={letter}>o</motion.span>
                  <motion.span variants={letter}>p</motion.span>
                  <motion.span variants={letter}>e</motion.span>
                  <motion.span variants={letter}>r</motion.span>
                </motion.span>
              </motion.h1>
            </div>
            <div className="w-full md:w-12/12 lg:w-4/12 mt-6 px-4 md:px-2">
              <motion.p className="text-xl" viewport={{ once: true }} animate={{ opacity: [0, 1] }} transition={{ delay: 1}}>I have over 4+ years of experience as a Front-End Development. For over the past 4 years, I have worked for some of the best IT companies and wonderful clients to create some award-winning works. And, I can make this happen for your business as well.</motion.p>
              <motion.h1 className="leading-none mt-6" viewport={{ once: true }} animate={{ fontSize: ['0', '2.8rem'], opacity: [0, 1] }} transition={{ delay: 1 }}>Based in Sri Lanka</motion.h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home