import React, { useRef } from 'react'
import './contact.css'
import { motion } from "framer-motion"
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fab, fas);

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_t70wawk', 'template_auw4hz8', form.current, 'n47xItJcW20Md3G2V')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
  };
  return (
    <div>
      <div className='py-[100px]' id='contact'>
        <div className="container mx-auto">
          <div className="flex flex-wrap -mx-2">
            <div className="w-full md:w-3/6 lg:w-4/8 px-4 md:px-2">
              <motion.h2 className='text-[60px] mb-8 leading-none' viewport={{ once: true }} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: .500 }}>Get in Touch</motion.h2>
              <div className='mt-10'>
                <ul>
                  <li className='mb-4'><a href="tel:+94 713 440 804"><FontAwesomeIcon icon="fa-solid fa-phone" size="lg" className='w-[40px]' />+94 713 440 804</a></li>
                  <li className='mb-4'><a href="mailto:madujayasooriya93@gmail.com"><FontAwesomeIcon icon="fa-solid fa-envelope" size="lg" className='w-[40px]' />madujayasooriya93@gmail.com</a></li>
                </ul>
              </div>
              <div className="mt-10">
                <a href="https://www.facebook.com/Madusanka.00j/" target="_blank" className='btn btn-dark text-white btn-square mr-3'><FontAwesomeIcon icon="fa-brands fa-facebook-f" size="lg" className='w-[40px]'/></a>
                <a href="https://www.linkedin.com/in/m-jayasooriya" target="_blank" className='btn btn-dark text-white btn-square'><FontAwesomeIcon icon="fa-brands fa-linkedin-in" size="lg" className='w-[40px]' /></a>
              </div>
            </div>
            <div className="w-full md:w-3/6 lg:w-4/8 px-4 md:px-2 mt-10 md:mt-0">
              <form ref={form} onSubmit={sendEmail}>
                <div className="flex flex-wrap -mx-2">
                  <div className="w-full md:w-6/12 px-4 md:px-2 mb-5">
                    <input type="text" placeholder="First Name" name='first_name' className="input input-bordered w-full" required />
                  </div>
                  <div className="w-full md:w-6/12 px-4 md:px-2 mb-5">
                    <input type="text" placeholder="Last Name" name='last_name' className="input input-bordered w-full" required />
                  </div>
                  <div className="w-full md:w-6/12 px-4 md:px-2 mb-5">
                    <input type="email" placeholder="Email" name='user_email' className="input input-bordered w-full" required />
                  </div>
                  <div className="w-full md:w-6/12 px-4 md:px-2 mb-5">
                    <input type="tel" placeholder="Phone" name='phone' className="input input-bordered w-full" required />
                  </div>
                  <div className="w-full md:w-12/12 px-4 md:px-2 mb-5">
                    <textarea className="textarea textarea-bordered w-full" name='message' rows={5} placeholder="Message"></textarea>
                  </div>
                  <div className="w-full md:w-12/12 px-4 md:px-2 mb-5">
                    <motion.button type='submit' whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="btn btn-dark text-white px-6 no-animation">Submit</motion.button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact